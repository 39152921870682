/* 文本颜色 */
@blue: #0052D9;
@c1: #1f1f20;
@c2: #646466;
@c3: #95969a;

.font14-500-c1 {
  font-size: 14px;
  font-weight: 500;
  color: @c1;
}

/* 背景色 */
@bcgary: #ececee;
@bc1: #ecf2fe;

/* 边框色 */
@border: #dcdcdc;

/* 提示色 */
@success: #00a870;
@warn: #ed7b2f;
@danger: #e34d59;

b {
  font-weight: lighter;
  cursor: pointer;
}

.custom-table-style {
  .ant-table-thead {
    >tr {
      >th {
        // background: white !important;
        padding-top: 10px;
        padding-bottom: 10px;
        // line-height: 35px;
      }
    }
  }

  .ant-table-column-title {
    flex: initial;
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-sorter {
    line-height: 12px;
  }

  // ::-webkit-scrollbar {
  //   /*隐藏滚轮*/
  //   display: none;
  //   }
  // overflow:-moz-scrollbars-horizontal;
  // overflow-x: scroll;

}

.custom-table-style2 {
  .ant-table-thead {
    >tr {
      >th {
        background: white !important;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 35px;
      }
    }
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 0 !important;
  }

  .ant-table-column-title {
    flex: initial;
  }

  .ant-table-column-sorters {
    justify-content: flex-start;
  }

  .ant-table-column-sorter {
    line-height: 12px;
  }
}

.ant-table-container {
  margin: 20px 0;
}

.ant-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.hoverImg {
  position: relative;

  .hoverBG {
    // visibility: hidden;
    opacity: 0;
    transition: all .25s;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    .magnify {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(/assets/images/magnify.png) no-repeat center center;
      background-size: 120%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 35%;
      cursor: pointer;
    }

    .delImg {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(/assets/images/delelet.png) no-repeat center center;
      background-size: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 35%;
      cursor: pointer;
    }
  }
}

.hoverImg:hover .hoverBG {
  // visibility: visible;
  opacity: 1;

}

/* 自定义查看大图 */
.showBigImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);
  z-index: 9999;
  overflow: scroll;

  img {
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .lengthTxt {
    color: #fff;
    font-size: 15px;
    position: absolute;
    z-index: 10002;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%)
  }

  .onLeft {
    width: 50px;
    height: 100px;
    line-height: 100px;
    background-color: rgba(255, 255, 255, .6);
    color: #fff;
    font-size: 22px;
    border-radius: 0 20px 20px 0;
    // cursor: pointer;
    position: absolute;
    z-index: 10002;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    text-align: center;
    // writing-mode: vertical-lr;/*从左向右 从右向左是 writing-mode: vertical-rl;*/  
    // writing-mode: tb-lr;/*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/  
  }

  .onRight {
    width: 50px;
    height: 100px;
    line-height: 100px;
    background-color: rgba(255, 255, 255, .6);
    color: #fff;
    font-size: 22px;
    border-radius: 20px 0 0 20px;
    // cursor: pointer;
    position: absolute;
    z-index: 10002;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    text-align: center;
    // writing-mode: vertical-lr;/*从左向右 从右向左是 writing-mode: vertical-rl;*/  
    // writing-mode: tb-lr;/*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/  
  }
}

/* 去除滚动条 */
.showBigImg::-webkit-scrollbar {
  width: 0 !important
}

.showBigImg {
  -ms-overflow-style: none;
}

.showBigImg {
  overflow: -moz-scrollbars-none;
}

.appmap {
  width: 100%;
  height: 400px;
}

// .amap-markers{
//   .amap-marker:first-child{
//     display: none !important;
//   }
// } 

.messageTemplateTxt {
  background-color: @bcgary;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}

.messageTemplateTxtActive {
  background-color: @blue;
  color: #fff;
}

/* 自定义标签 */
.tagItem {
  background-color: #eeeeee;
  padding: 4px 10px;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  transition: all .25s;
  // cursor: cell;
  cursor: default;
  border-radius: 3px;

  .tagSplice {
    transition: all .25s;
    padding-left: 10px;
    cursor: default;
  }

  .tagSplice:hover {
    color: #ad463f !important;
    transform: scale(1.1);
  }
}

.tagItemActive {
  background-color: #0052d9;
  color: rgba(255, 255, 255, .8);
}

.addTag {
  border: 1px dashed @border;
  padding: 4px 10px;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  cursor: grabbing;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}

.ant-popover-message-title {
  width: 240px;
  white-space: pre-wrap;
}

/* --------- */


.QRCode {
  width: 160px;
  height: 160px;
  position: relative;
  top: 100px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.successInfo {
  width: 100vw;
  height: 100vh;
  position: relative;

  div {
    position: absolute;
    top: 30%;
    left: 40%;
    transform: translateX(-100%);
    text-align: center;

    img {
      width: 64px;
      height: 64px;
    }

    h2 {
      margin-top: 30px;
      font-weight: bold;
    }

    .ssbtn {
      margin-top: 30px;
    }
  }
}

.tableTitle {
  border: 1px solid @border;

  ul {
    margin-bottom: 0 !important;
    width: 100%;
  }

  li {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    width: 100px;
    background-color: #f7f7f7;
    text-align: center;

    // margin: 0 5px;
    .ant-input {
      border: none !important;
      width: 80%;
    }

    .ant-input:hover {
      border: 2px solid #5e92e7;
    }
  }

}

.box {
  // background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 10px 15px;
  margin-bottom: 20px;
}

.txtPanel {
  .ant-space-item:first-child {
    width: 80px;
  }
}

.TableHoverBtn {
  p {
    cursor: alias;
    padding: 5px 12px;
  }

  p:hover {
    background-color: #ECF2FE;
    color: #0052D9;
  }
}


.searchFrom {
  .ant-btn {
    width: 88px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    padding: 0;
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
    font-size: 14px;
    font-weight: 400;
  }

  .searchBtn {
    border: 1px solid #0052d9;
    color: #0052d9;
  }

  .searchReset {
    color: #1f1f20;
  }
}

/* 生成邀请码第二个弹窗 */
.yqmPop {
  .ant-input-disabled {
    background-color: #fff;
    border: none;
  }

  .txtarea {
    border: 2px solid #ebecec !important;
  }
}

.map-app {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;

  .map-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .map-ipt {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 250px;
    z-index: 100;
  }

  .position-text {
    // position: absolute;
    // top: 10px;
    // right: 10px;
    color: #000;
    width: 100%;
    text-align: center;
    // font-weight: bold;
  }

}

.fileUploadBox {
  width: 140px;
  display: inline-block;
  margin: 5px;
  position: relative;
  float: left;
  border: 1px solid #fff
}

.fileUploadHover:hover {
  border: 1px solid rgb(0, 82, 217)
}

.fileUploadDelHover:hover {
  border: 1px solid red
}

.inpCheckBox ::selection {
  background: red;
}

.cardActive {
  background: #ecf2fe;
  border: 1px solid #0052d9;
  border-radius: 2px;
  position: relative;
}

.cardActive:before {
  display: block;
  content: '';
  // border-width:12px 12px 12px 12px;
  border-width: 6px;
  // border-left:12px solid blue;
  border-style: solid;
  border-color: transparent transparent transparent blue;

  /* 定位 */
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
}

/* 去除滚动条 */
.noScrollbar {
  &::-webkit-scrollbar {
    width: 0px;
  }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 4px;
  //   // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.9);
  //   background: #909090;
  // }
}